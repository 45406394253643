import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image404"

const NotFoundPage = () =>  {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title={`404: Not found | ${site.siteMetadata.title}`} />
      <Image/>
    </Layout>)
}

 

export default NotFoundPage

